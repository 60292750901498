header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    color: white;
    max-width: 1200px;
    margin: auto;

    >div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    h1,
    h2 {
        font-weight: normal;
        font-family: "chalk";
        text-align: center;
        margin: 0.2em 0;
        font-size: 2.5em;

        img {
            vertical-align: sub;
            height: 1.2em;
            margin: 0;
            padding: 0;
        }
    }

    h2 {
        font-size: 2em;
    }

    h1 img {
        height: 1em;
        vertical-align: top;
    }

    >a {
        display: inherit;

        img {
            height: 7.5em;
        }
    }
}